/* eslint-disable import/no-unresolved */

// unused pages
// Admin pages

import _ from 'lodash';
import CrudWrapper from '@/pages/CrudWrapper.vue';
import Overview from '@/components/Dashboard/Views/Overview.vue';

import Profile from '@/pages/user/Profile.vue';
import Login from '@/pages/GeneralViews/Login.vue';
import NotFound from '@/pages/GeneralViews/NotFoundPage.vue';
import Register from '@/pages/GeneralViews/Register.vue';
import Reset from '@/pages/GeneralViews/Reset.vue';
import QuizManager from '@/pages/QuizManager.vue';
import WorkshopManager from '@/pages/WorkshopManager.vue';

/* eslint-enable import/no-unresolved */
import config from '@/config';
import rolesMixin from '../mixins/rolesMixin';
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue';
// GeneralViews
import store from '../stores';

const authGuard = (to, from, next) => {
  if (store.state.user && store.state.user[config.primaryKey]) {
    return next();
  }
  console.warn('Authgard:: Blocked : ', to.path, store.state.user);
  next('/login');
};

// eslint-disable-next-line
const roleGuard = role => (to, from, next) => {
  if (!store.state.user || !store.state.user[config.primaryKey]) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('Rolegard::', 'User not connected, forcing logout');
    }
    store.commit('logout');
    return next('/login');
  }

  if (!store.state.user.roles || !rolesMixin.methods.userHasRole(role, store)) {
    next('/');
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log('Rolegard:: missing role ', role, 'redirecting');
    }
    return;
  }
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    console.log('Rolegard::', 'correct role');
  }
  return next();
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auth/google/callback',
    name: 'Google',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/reset-password/:token',
    name: 'Reset',
    component: Reset,
    props: true,
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/app/overview/home',
  },
  {
    path: '/app',
    component: DashboardLayout,
    redirect: '/app/overview/home',
    beforeEnter: authGuard,
    children: [
      {
        path: 'overview/home',
        name: 'Overview',
        component: Overview,
        beforeEnter: authGuard,
      },
      {
        path: 'quiz/:id/manage',
        name: 'ManageQuiz',
        component: QuizManager,
      },
      {
        path: 'workshop/:id/manage',
        name: 'ManageWorkshop',
        component: WorkshopManager,
      },
      {
        path: 'user-profile',
        name: 'UserProfile',
        component: Profile,
        beforeEnter: authGuard,
      },
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

const handleNestedModels = (models) => {
  if (!models) {
    return;
  }

  // Handle 'programDay' model
  const programDayModel = models.find((model => model.identity === 'programDay'));

  if (programDayModel) {
    const programDayModelUrl = '{{ currentItem && currentItem.id ? "/api/program_day" : parent ? "/api/program_day?filters[programId]=" + parent.id : "/api/program_day" }}';
    programDayModel.url = programDayModelUrl;
    programDayModel.apiUrl = programDayModelUrl;
    programDayModel.nestedDisplayMode = 'table';
    programDayModel.actions.search = false;
    programDayModel.actions.advancedFiltering = false;

    const programDayMediaModel = _.cloneDeep(
      models.find(md => md.identity === 'programDayMedia'),
    );

    if (programDayMediaModel) {
      const programDayMediaModelUrl = '{{ currentItem && currentItem.id ? "/api/program_day_media" : parent && parent.id ? "/api/program_day_media?filters[programDayId]=" + parent.id : "/api/program_day_media" }}';
      programDayMediaModel.url = programDayMediaModelUrl;
      programDayMediaModel.apiUrl = programDayMediaModelUrl;
      programDayMediaModel.nestedDisplayMode = 'table';
      programDayMediaModel.actions.search = false;
      programDayMediaModel.actions.advancedFiltering = false;

      programDayModel.nestedModels = [{ ...programDayMediaModel }];
    }
  }

  // Handle 'program' model
  const programModel = models.find((model => model.identity === 'program'));

  if (programModel && programDayModel) {
    programModel.nestedModels = [{ ...programDayModel }];
  }
};

const connectModelToRoute = (model, parentRoute) => {
  if (!model || !model.identity) {
    console.warn('missing data for model', model);
    return;
  }

  parentRoute.children.push({
    name: model.name || model.identity,
    path: model.routerPath || _.kebabCase(model.identity),
    component: CrudWrapper,
    beforeEnter: authGuard,
    props: () => ({
      identity: model.identity,
      storePath: 'state.model.models',
      title: model.pageTitle
    }),
    children: [
      {
        name: `${model.name}-view`,
        path: ':id',
        component: CrudWrapper,
        props: {
          identity: model.identity,
          title: model.pageTitle,
          storePath: 'state.model.models',
          ...model,
          model,
          options: _.merge(model.options, model.viewOptions),
          nestedModels: model.nestedModels,
          nestedDisplayMode: 'table',
        },
      },
      {
        name: `${model.name}-edit`,
        path: ':id/edit',
        component: CrudWrapper,
        props: {
          ...model,
          model,
          identity: model.identity,
          title: model.pageTitle,
          options: _.merge(model.options, model.editOptions),
          nestedModels: model.nestedModels,
          nestedDisplayMode: 'table',
        },
      },
    ],
  });
};

const appRoute = routes.find(route => route.path === '/app');
const models = _.get(store, 'state.model.models');
if (config.features && config.features.autoWireAllModels && models) {
  if (models && models.length) {
    if (config.automaticModels) {
      config.automaticModels = [];
    }
    config.automaticModels = config.automaticModels.concat(models);
  }
}
if (config.automaticModels && config.automaticModels.length) {
  handleNestedModels(config.automaticModels);
  config.automaticModels.forEach(model => connectModelToRoute(model, appRoute));
}


export default routes;
export { authGuard, roleGuard, routes };
