<template>
  <div class="main-wrapper">
    <side-bar backgroundColor="primary">
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/app/overview/home">
        <i class="fa fa-table"></i>
        <div>{{ $t('common.labels.dashboard') }}</div>
      </sidebar-link>
      <div>
        <template>
          <h4 class="ml-3 pointer" data-toggle="collapse" data-target="#global-menu">
            <i class="fa fa-caret-right"></i>
            Sections
          </h4>
          <div id="global-menu">
            <div class="menu-editable">
              <sidebar-link to="/app/user">
                <i class="fa fa-user"></i>
                <div class="text-truncate">Utilisateurs</div>
              </sidebar-link>
              <sidebar-link to="/app/profile">
                <i class="fa fa-code"></i>
                <div class="text-truncate">Profils</div>
              </sidebar-link>
              <sidebar-link v-if="userHasRole('ADMIN')" to="/app/program">
                <i class="fa fa-bank"></i>
                <div class="text-truncate">Programmes</div>
              </sidebar-link>
              <sidebar-link v-if="userHasRole('ADMIN')" to="/app/media">
                <i class="fa fa-book"></i>
                <div class="text-truncate">Medias</div>
              </sidebar-link>
              <sidebar-link v-if="userHasRole('ADMIN')" to="/app/quiz">
                <i class="fa fa-question"></i>
                <div class="text-truncate">Questionnaires</div>
              </sidebar-link>
              <sidebar-link to="/app/subscription-product" v-if="userHasRole('ADMIN')">
                <i class="fa fa-code"></i>
                <div class="text-truncate">Subscription Products</div>
              </sidebar-link>
              <sidebar-link to="/app/user-subscription" v-if="userHasRole('ADMIN')">
                <i class="fa fa-code"></i>
                <div class="text-truncate">User Subscriptions</div>
              </sidebar-link>
              <sidebar-link to="/app/study">
                <i class="fa fa-code"></i>
                <div class="text-truncate">Études</div>
              </sidebar-link>
              <sidebar-link v-if="userHasRole('ADMIN')" to="/app/goal">
                <i class="fa fa-trophy"></i>
                <div class="text-truncate">Objectifs</div>
              </sidebar-link>
              <sidebar-link to="/app/badge" v-if="userHasRole('ADMIN')">
                <i class="fa fa-code"></i>
                <div class="text-truncate">Badges</div>
              </sidebar-link>
              <sidebar-link v-if="userHasRole('ADMIN')" to="/app/tag">
                <i class="fa fa-book"></i>
                <div class="text-truncate">Tags</div>
              </sidebar-link>
            </div>
          </div>
        </template>
      </div>

      <div v-if="false && config.env === 'development'">
        <h4 class="ml-3" data-toggle="collapse" data-target="#wired-menu"><i class="fa fa-caret-right"></i> {{ $t('autowired') }}</h4>
        <div id="wired-menu" class="collapse collapsed">
          <div v-for="model of $store.state.model.models" :key="model.name" class="menu-editable">
            <sidebar-link :to="'/app/' + (model && model.routerPath ? model.routerPath : kebabCase(model.identity))">
              <i :class="model.icon ? 'fa fa-' + model.icon : 'fa fa-caret-right'"></i>
              <div class="text-truncate">
                {{ $t(startCase(model.namePlural ? model.namePlural : model.name ? model.name : model.identity)) }}
              </div>
            </sidebar-link>
            <button v-if="crudConfigMode" class="btn btn-sm btn-link edit-icon" @click.prevent="editModel(model)">
              <i class="fa fa-cog pt-1"></i>
            </button>
          </div>
          <hr />
          <small id="wired-menu" class>
            <button class="btn btn-link text-light btn-block" @click="$store.dispatch('getModels')">
              <i class="fa fa-cog"></i>
              refresh models
            </button>
          </small>
        </div>
      </div>

      <div style="position: fixed; bottom: 1px; font-size: 10px; padding: 2px 5px; margin-bottom: 3px">
        Version: {{ config.version }}
        <br />
        Built: {{ config.buildDate }}
      </div>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
    <AwesomeForm
      :standalone="false"
      :identity="editedModel.identity"
      v-if="editedModel && editedModel.identity && isEditingConfig"
      :model="configModel"
      :item="editedModel"
      mode="edit"
      displayMode="sidebar"
      primaryKey="identity"
      @closeRequested="() => (this.isEditingConfig = false)"
      @cancel="() => (this.isEditingConfig = false)"
    />
  </div>
</template>
<style lang="scss"></style>
<script>
import { rolesMixin, AwesomeForm } from 'vue-aw-components';
import { kebabCase, startCase } from 'lodash';
import config from '@/config';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './MobileMenu.vue';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    AwesomeForm,
  },

  mixins: [rolesMixin],

  data() {
    return {
      config,
      providerCode: '',
      crudConfigMode: true,
      isEditingConfig: false,
      editedModel: {},
    };
  },

  mounted() {
    this.providerCode = this.$store.state.user && this.$store.state.user.providerCode;
  },

  computed: {
    configModel() {
      return this.$store.state.model && this.$store.state.model.models
        ? this.$store.state.model.models.find((m) => m.identity === 'axelModelConfig')
        : {};
    },
  },

  methods: {
    kebabCase,
    startCase,
    loadDynamicPages() {
      this.$store.dispatch('getModels').then(() => {
        this.$notify('Models successfully updated');
      });
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    refreshRouter() {},

    toggleCrudConfigMode() {
      this.crudConfigMode = !this.crudConfigMode;
    },

    editModel(model) {
      this.isEditingConfig = true;
      this.editedModel = model;

      //     this.$router.push(`/app/esails_entity_config/${model.identity}/edit`);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-icon {
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  margin-right: -10px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms linear 200ms;
}

.menu-editable {
  position: relative;

  &:hover {
    .edit-icon {
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>
